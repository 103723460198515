import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>PWA jako alternatywa dla hybrydowych aplikacji mobilnych!</h1>
    <p>Ta aplikacja korzysta z Service Worker - więc z łatwością możesz zainstalować ją na swoim urządzeniu.</p>
  </Layout>
)

export default IndexPage


